import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { DatePicker, KeyboardDatePicker, usePickerState } from "@material-ui/pickers";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { format } from "date-fns";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: '20px',
      paddingBottom: '20px',
    },
    clear: {
        marginTop: 5,
        cursor: 'pointer',
        fontSize: 13,
        color: theme.palette.grey[600]
    },
    date: {
        transform: 'translateY(16px)'
    },
    formControl: {
        marginTop: theme.spacing(2),
    },  
    formGroup: {
        display: 'flex',
        fleWrap: 'inherit',
        flexDirection: 'row'
    }  
  }));

const Dates = ({makeRequest}) => {

    const classes = useStyles();    
    let location = useLocation();
    const history = useHistory();

    const [selectedDate, handleDateChange] = useState(null);
    const [selectedDateEnd, handleDateChangeEnd] = useState(null);

    const [type, setType] = useState({
        offline: true,
        demo: false,
        webinar: false
    });

    const { offline, demo, webinar } = type;

    const pushParams = (date, type) => {
        history.push({
            pathname: location.pathname,
            search: '?datestart='+date.start+'&dateend='+date.end+'&type='+type.offline+','+type.demo+','+type.webinar
        })
    }

    const searchParams = () => {
        let url = window.location.href;
        let urlUrl = new URL(url);
        let params = new URLSearchParams(urlUrl.search.slice(1));
        let array = [];

        for (let p of params) {
            array.push(p);            
        }

        return array;
    }

    const dateParser = (data) => {
        if(data != "null" && data != null){
            var [day, month, year] = data.split('-');
            return Date.parse(month+'/'+day+'/'+year);
        }
        else{
            return null;
        }
    }


    const handleChange = (event) => {
        setType({ ...type, [event.target.name]: event.target.checked });
    };   

    const cliearStart = () => {
        handleDateChange(null);
    }

    const cliearEnd = () => {
        handleDateChangeEnd(null);
    }


    const handleRequest = () => {
        
        const dates = {
            start: selectedDate ? format(new Date(selectedDate), "dd-MM-yyyy") : null,
            end: selectedDateEnd ? format(new Date(selectedDateEnd), "dd-MM-yyyy") : null
        };  

        const types = type;
        makeRequest({dates, types});
        pushParams(dates, types);

    };

    const handleRequestInLoad = (param) => {
        
        console.log(param);

        let dates = {
            start: '',
            end: ''
        }; 
        let types = {
            offline: null,
            demo: null,
            webinar: null
        };                 

        for(let i = 0; i < param.length; i++){
            if(param[i][0] == 'datestart'){
                dates['start'] = param[i][1];                
            } else if(param[i][0] == 'dateend'){
                dates['end'] = param[i][1];
            } else if(param[i][0] == 'type'){
                types['offline'] = param[i][1].split(',')[0] == "true" ? true : false;
                types['demo'] = param[i][1].split(',')[1] == "true" ? true : false;
                types['webinar'] = param[i][1].split(',')[2] == "true" ? true : false;
            }
        }

        const parseStart = dateParser(dates.start);
        const parseEnd = dateParser(dates.end);

        setType(types);
        handleDateChange(parseStart? new Date(parseStart): null);
        handleDateChangeEnd(parseEnd? new Date(parseEnd): null);   

        makeRequest({dates, types});

    };    

    useEffect(() => {
        if(searchParams().length > 0){
            handleRequestInLoad(searchParams());
        }
    }, [])

    return (
        <Container className={classes.root} maxWidth="lg">
            <Grid container alignItems="flex-end" spacing={3}>
                <Grid item xs={12}>
                    <p>
                        Choose the date range and the type of event, then click generate.
                    </p>
                </Grid>
                <Grid item xs={2} className={classes.date}>
                    <DatePicker
                        autoOk
                        label="Start Date"
                        variant="inline"
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        animateYearScrolling
                    />  
                    <div className={classes.clear} onClick={cliearStart}>Clear</div>
                </Grid>
                <Grid item xs={2} className={classes.date}>                  
                    <DatePicker
                        autoOk
                        label="End Date"
                        variant="inline"
                        format="dd/MM/yyyy"
                        value={selectedDateEnd}
                        onChange={handleDateChangeEnd}
                        animateYearScrolling
                    />    
                    <div className={classes.clear} onClick={cliearEnd}>Clear</div>
                </Grid>
                <Grid item xs={4} className={classes.date}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Type of reservation</FormLabel>
                        <FormGroup className={classes.formGroup}>
                            <FormControlLabel
                                control={<Checkbox checked={offline} onChange={handleChange} name="offline" />}
                                label="Offline"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={demo} onChange={handleChange} name="demo" />}
                                label="Demo"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={webinar} onChange={handleChange} name="webinar" />}
                                label="Webinar"
                            />
                        </FormGroup>
                    </FormControl>                    
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" color="primary" onClick={handleRequest}>
                        Generate
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );

}

export default Dates;
