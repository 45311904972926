import 'date-fns';
import 'handsontable/dist/handsontable.full.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './Views/Home';
import Fabs from './Views/Fabs';
import Agency from './Views/Agency';
import Sales from './Views/Sales';
import Showmanager from './Views/Showmanager';
import { Provider } from 'react-redux';
import { store } from './redux/store';

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/fabs" component={Fabs} />
          <Route path="/agency" component={Agency} />
          <Route path="/showroommanager" component={Showmanager} />
          <Route path="/sales" component={Sales} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
