import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    reservation: null,
    isLoading: false    
};

const slice = createSlice({
    name: 'reservation',
    initialState,
    reducers: {
        startLoading(state){
            state.isLoading = true;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // MAKE IT
        makeRequest(state, action) {
            state.isLoading = false;
            state.reservation = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const { makeRequest } = slice.actions;

// ----------------------------------------------------------------------

export function getRangeReservation({column, dates, types}) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL+'/reservation/get?column=' + column + '&date=' + dates + "&type=" + types);
        dispatch(slice.actions.makeRequest(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
}