import 'date-fns';
import 'handsontable/dist/handsontable.full.css';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HotTable } from '@handsontable/react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import dateFormat from "../../utils/tabledateformat";
import Handsontable from 'handsontable';
import Button from '@material-ui/core/Button';
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 40
    }
}));


const Grids = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { reservation, isLoading } = useSelector((state) => state.reservation);
    const [headers, setHeaders] = useState();
    const [reservations, setReservations] = useState();
    
    const dateParser = (data) => {
        console.log('data', data);
        if(data != "null" && data != null){
            var [day, month, year] = data.split('/');
            return Date.parse(month+'/'+day+'/'+year);
        }
        else{
            return null;
        }
    }

    useEffect(() => {
        if(reservation && reservation[0] !== undefined){
            setHeaders(reservation[0].map(el => t(el)));
            
            /**
             * Exclude the first array from the reservation
             */            
            let res = reservation.filter((e,i) => {
                if(i > 0){
                    return e;
                }
            });
            
            const dates = dateFormat(res, reservation[0]);
            
            setReservations(dates);
        } else {
            setHeaders(null);
        }
    },[reservation]);
    
    
    const container = document.querySelector('#example2');
    // hottable
    if(container && container.childElementCount === 0){
        const button = document.querySelector('#export-blob');
        const hot = new Handsontable(container, {
            data: reservations,
            colHeaders: headers,
            rowHeaders: true,
            dropdownMenu: true,
            
              columnSorting: {
                sortEmptyCells: true,
                indicator: true,
                compareFunctionFactory(sortOrder, columnMeta) {
                    //console.log(columnMeta.col, sortOrder)
                    
                  return function comparator(value, nextValue) {
                    // Some value comparisons which will return -1, 0 or 1...
                        if(columnMeta.col === 3){

                            console.log(value, nextValue)
                            value = format(dateParser(value), "yyyy-MM-dd");
                            nextValue = format(dateParser(nextValue), "yyyy-MM-dd");
                            console.log(value)
                            //return (sortOrder === 'asc'? value.getTime() > nextValue.getTime() : value.getTime() < nextValue.getTime())
                        }
                        
                        //console.log(value)
                         value === null ? value = '': value = value;
                         nextValue === null ? nextValue = '': nextValue = nextValue;
                    return (sortOrder === 'asc' ? (value > nextValue) : (value < nextValue))
                  }; // Don't sort the first visual column.

                }
              
            },
            filters: true,
            width: 'auto',
            height: 'auto',
            licenseKey: '8353f-d56c8-a6d62-9430e-11e30',
            
        });
        
        const exportPlugin = hot.getPlugin('exportFile');
        // button for export
        button.addEventListener('click', () => {
        exportPlugin.downloadFile('csv', {
            bom: false,
            columnDelimiter: ',',
            columnHeaders: true,
            exportHiddenColumns: true,
            exportHiddenRows: true,
            fileExtension: 'csv',
            filename: 'Report-'+props.name+'-file_[YYYY]-[MM]-[DD]',
            mimeType: 'text/csv',
            rowDelimiter: '\r\n',
            rowHeaders: false
        });
        });
            }
     
    
    if(isLoading){
        return (
            <Container className={classes.root}>
                <Grid container alignItems="center" justifyContent="center" spacing={3}>
                    <Grid item xs={1}>
                        <CircularProgress />
                    </Grid>
                </Grid>    
            </Container>
        );
    } else if(reservation === null){
        return(
            <Container className={classes.root}>
                <Typography variant="h6" color="primary" align="center">                    
                    Make your research
                </Typography>
            </Container>
            );
    } else if(reservation.length === 0){
        return(
            <Container className={classes.root}>
                <Typography variant="h6" color="error" align="center">
                    No Result
                </Typography>
            </Container>            
        );
    }else{        
        return(
            <>
                <div id="example2"></div>
                <div className="controls" style={{paddingTop:'40px'}}>
                    <Button variant="contained" color="primary" id="export-blob">Export as a CSV</Button>
                </div>
            </>
        );
    }

}

const Table = (props) => {

    const classes = useStyles();

    return (
        <Container style={{width: '100%', maxWidth: "100%", marginTop: '50px'}}>        
            <Grids name={props.name}/>
        </Container>
    );

}

export default Table;
