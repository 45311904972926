const clearDate = (date) => {

    if(date.start && date.end){
        date = date.start+','+date.end;
    } else if(date.start && date.end == null){
        date = date.start;
    } else if(date.start == null){
        date = null;
    } else {
        date = null;
    }

    return date
}

export default clearDate;