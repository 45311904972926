import { format } from "date-fns";

function DateFormat(data, reservation) {
    /**
     * Get the index of the EventStartDate for the next mapping
     */
    let dateStart = reservation.indexOf('EventStartDate');
    let dateEnd = reservation.indexOf('EventEndDate');
    let dateConfirm = reservation.indexOf('ReservationConfirmationDate');

    /**
     * Conver the date the the european format with hours
     */
    let dates = data.map(line => {
        return line.map((e, i) => {
            if(i === dateStart || i === dateConfirm || i === dateEnd){
                return e ? format(new Date(e), "dd/MM/yyyy HH:mm") : ''
            }                    
            return e
        });
    });    

    return dates
}

export default DateFormat;