import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from "../Components/Page";
import DatesBar from "../Components/Elements/DatesBar";
import Table from "../Components/Elements/Table";
import { getRangeReservation } from "../redux/slices/reservation";
import clearedate from "../utils/cleardate";
import cleartype from "../utils/cleartype";

const Fabs = () => {

    const dispatch = useDispatch();

    const column = "ReservationId,ReservationConfirmationDate,Status,ReservationCode,Validator,EventName,EventType,EventStartDate,EventEndDate,UserAnalyticsId,UserEmail,UserFirstNamem,UserLastName,UserCompanyName,UserPhone,UserCountry,Content1,Content2,Content3,Content4,people,peoplenamerole,hotel,checkinout,singlerooms,doublerooms,personincharge,machines,tourbiesse,tourintermac,demosoftware,demoparts";

    // getRangeReservation

    const makeRequest = (data) => {
        console.log(clearedate(data.dates));
        if(clearedate(data.dates)){
            dispatch(getRangeReservation({column, dates: clearedate(data.dates), types: cleartype(data.types)}));            
        } else {
            console.log('make it');
        }
    }

    return (
        <Page title="fabs" >
            <DatesBar makeRequest={makeRequest}/>
            <Table />
        </Page>
    );

}

export default Fabs;