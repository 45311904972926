import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from "../Components/Page";
import DatesBar from "../Components/Elements/DatesBar";
import Table from "../Components/Elements/Table";
import { getRangeReservation } from "../redux/slices/reservation";
import clearedate from "../utils/cleardate";
import cleartype from "../utils/cleartype";
import { useHistory } from "react-router-dom";

const Fabs = () => {

    const dispatch = useDispatch();

    const history = useHistory();

    const column = "Status,Validator,EventName,EventStartDate,UserCompanyName,UserCountry,provincia,people,personincharge,dealer,machines,demosoftware,demoparts,UserEmail";

    // getRangeReservation

    const makeRequest = (data) => {
        console.log(clearedate(data.dates));
        if(clearedate(data.dates)){
            dispatch(getRangeReservation({column, dates: clearedate(data.dates), types: cleartype(data.types)}));            
        } else {
            console.log('make it');
        }
    }

    return (
        <Page title="Show room manager" >
            <DatesBar makeRequest={makeRequest}/>
            <Table name={'ShowroomManager'}/>
        </Page>
    );

}

export default Fabs;