import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import reservation from "./slices/reservation";


// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const rootReducer = combineReducers({
  reservation: reservation,
});

export { rootPersistConfig, rootReducer };
