import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from "../Components/Page";

const Home = () => {

    const dispatch = useDispatch();

    const column = "ReservationId,ReservationConfirmationDate,Status,ReservationCode,Validator,EventName,EventType,EventStartDate,EventEndDate,UserAnalyticsId,UserEmail,UserFirstNamem,UserLastName,UserCompanyName,UserPhone,UserCountry,Content1,Content2,Content3,Content4,people,peoplenamerole,hotel,checkinout,singlerooms,doublerooms,personincharge,machines,tourbiesse,tourintermac,demosoftware,demoparts";

    // getRangeReservation

    return (
        <Page title="Home" >

        </Page>
    );

}

export default Home;