const clearType = (type) => {

    let types = '';

    let counter = 0;
    for (const [key, value] of Object.entries(type)) {
        
        if(value == true){
            if (counter > 0){
                types += ',';
            }                    
            types += key;
            counter++;
        }        
    }

    return types
}

export default clearType;